html, body, #root {
  height: 100%;
}
.App {
  height: 100%;
}

.chart {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  color: var(--color, white);
  text-align: left;
}
.datum {
  margin-right: 0.4em;
  margin-bottom: 0.2em;
}
.datum .label {
  line-height: 1;
  margin-bottom: 0.2em;
}
.datum .value {
  padding-left: 0.4em;
  text-align: right;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.3em;
  font-weight: bold;
  line-height: 1;
}
.datum .value .bar {
  box-sizing: border-box;
  padding-right: 10px;
  margin-right: 10px;
  transition: width 300ms 0s ease;
  overflow: hidden;
  box-shadow:3px 3px 0px 0px #aaa;
  min-height: 1em;
}
.datum .value .ratio {
  right: -60px;
  text-align: left;
  display: block;
}
